<template>
  <maps-yandex-maps-saved-addresses
    v-if="appConfig.VueSettingsPreRun.MapsProvider === 'yandex'"
    :address="address"
  />
  <span
    v-else
    class="v-error-color"
    v-html="translate('mapsAddress.error')"
  />
</template>

<script setup lang="ts">
import type { MapsSavedAddress } from '~types/accountStore'

defineProps<{
  address: MapsSavedAddress | undefined
}>()

const { translate } = useI18nSanitized()
const appConfig = useAppConfig()
</script>
